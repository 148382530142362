import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaMinus, FaPlus } from "react-icons/fa"; // Only import FaBars here
import SearchBox from "./SearchBox";
import AimSection from "./AimSection";
import InsightsSection from "./InsightsSection";
import TradingSummarySection from "./TradingSummarySection";
import ModelSection from "./ModelSection";
import TestimonialSection from "./TestimonialSection";
import TeamSection from "./TeamSection";
import RedditSection from "./RedditSection";
// import BlogSection from './BlogSection';
import Footer from "./Footer";
import MobileMenu from "./MobileMenu"; // Import the new MobileMenu component
import "./LandingPage.css";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { logUserActivity } from "./logActivity";

// Import Swiper and its required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import WavePattern from "./WavePattern";
import Marquee from "react-fast-marquee";
import Amazon from "../../src/assets/images/amazon-logo.png";
import CMU from "../../src/assets/images/cmu-logo.png";
import CS from "../../src/assets/images/cs-logo.png";
import Pinterest from "../../src/assets/images/pinterest-logo.png";
import UCB from "../../src/assets/images/ucb-logo.png";
import UCLA from "../../src/assets/images/ucla-logo.png";
import Wharton from "../../src/assets/images/wharton-logo.png";
import { Helmet } from 'react-helmet';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
}

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [ticker, setTicker] = useState(""); // Store the ticker input
  const [user, setUser] = useState(null); // Store current user
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu state
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTicker = params.get("ticker");
  const width = useWindowWidth();

  const handleRedirect = () => {
    const params = new URLSearchParams(location.search);
    const pid = params.get("pid");

    if (user) {
      if (pid) {
        navigate(`/dashboard?pid=${pid}`); // Redirect to Report View Page
      } else {
        navigate("/dashboard"); // Redirect to Analyze Page
      }
    } else {
      navigate("/login"); // Redirect to login for unauthenticated users
    }
  };
  useEffect(() => {
    if (urlTicker) {
      handleFetchData(urlTicker.toUpperCase());
    }
  }, [urlTicker]);

  const handleFetchData = async (ticker) => {
    if (!ticker) {
      alert("Please enter a ticker symbol");
      return;
    }

    // Navigate to the progress page and pass the ticker before making the API call
    navigate("/progress", { state: { ticker } });
  };

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let userId = localStorage.getItem("uid"); // Get user ID from localStorage
    if (!userId) {
      userId = "0"; // Default to 0 if no UID is found
    }
    logUserActivity(userId, "HME"); // Use 'HME' for the LandingPage
    console.log(`User activity logged for page type: HME with uid: ${userId}`);
  }, []); // This runs only once when the component is mounted

  return (
    <div className="landing-page">
      <div className="product-page" id="product-page">
        <Helmet>
            <title>MarketCrunchAI: Use our Advanced AI-Models for Predictive Price Insights.</title>
            <meta name="description" 
              content="Discover real-time trading analysis, trending market data, and AI-driven stock picks with MarketCrunch AI. Boost your strategy for informed investment decisions."/>
            <meta name="keywords" 
              content="MarketCrunch AI, AI stock picks, real-time trading, trending markets, trading analysis, investment research"/>
        </Helmet>
        {/* Header */}
        <div className="header">
          {/* <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", backgroundColor: "#ffcc00", padding: 5 }}>
            <span style={{ fontWeight: 600, fontFamily: "Inter", fontSize: 14 }}>We are experiencing technical issues, and working hard to resolve them! If you have any questions, contact <a href="mailto:support@marketcrunch.ai">support@marketcrunch.ai</a></span>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            {/* Logo */}
            <h3 style={{ display: "inline-block", position: "relative" }}>
              <a
                href="#top"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                MarketCrunch AI
                <span
                  style={{
                    fontSize: "0.75em",
                    position: "relative",
                    top: "-0.2em",
                  }}
                >
                  &#8482;
                </span>
                <span
                  style={{
                    display: "block",
                    fontSize: "0.5em",
                    position: "absolute",
                    left: "75%",
                    top: "2.3em",
                    fontStyle: "italic",
                    letterSpacing: "0.05em",
                  }}
                >
                  BETA
                </span>
              </a>
            </h3>

            {/* Header Right: Sign-in Button and Hamburger Menu */}
            <div className="header-right">
              {/* Navigation Links for Desktop */}
              <div className="tabs-button-wrapper">
                <div className="tabs" style={{ marginBottom: 0 }}>
                  <a href="#product">Product</a>
                  <a href="#testimonials">Testimonials</a>
                  <a href="#team">Team</a>
                </div>
              </div>
              <button className="signin-button" onClick={handleRedirect}>
                {user ? "Dashboard" : "Log In"}
              </button>
              <div
                className="menu-icon"
                onClick={toggleMenu}
                aria-label="Toggle navigation menu"
              >
                <FaBars />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

        {/* Background Section */}
        {/* <div id="background"></div> */}
        <WavePattern />

        {/* Product Section */}
        <div className="content" style={{ zIndex: 5, position: "relative" }}>
          <h1 className="landing-header-text">
          AI-Driven Stock Insights in Seconds.
            <br />
            {/* <h1 className="landing-header-bold-text">
              Under a Minute (not hours).
            </h1> */}
          </h1>
          <p className="landing-header-subtext">
            Stop sifting through data. Our AI-Engine analyzes technical indicators, pricing history, news, social sentiment, and insider trading—delivering reliable insights fast.<br />
            <b>No hallucinations.</b>
          </p>
          {/* Remove comment markers to enable search box */}
          <SearchBox
            onSearch={handleFetchData}
            loading={loading}
            buttonText="Try it Free"
            isLanding={true}
          />
          <p className="landing-searchbox-subtext">
            Over 2000 users track 120K+ stocks and more.
          </p>
        </div>
      </div>

      {/* Swiper Carousel */}
      <div className="carousel-container" id="product">
        <div className="landing-section-name">Product</div>
        <span className="landing-section-title">MarketCrunchAI's Edge:</span>
        <p className="landing-section-subtext">
          <b>We do the math</b>—to give you an edge. AI—powered research has revolutionized investing, but 
          such tools are privy to large institutions. We are on a <b>mission to bring quant-based 
          research to all investors.</b> Stop Charting :)
        </p>

        {/* <TestimonialSection /> */}

        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 7000 }}
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          style={{ borderRadius: 15, color: "#F8F8F5", marginTop: "3em" }}
        >
          <SwiperSlide style={{ height: "100%" }}>
            <AimSection />
          </SwiperSlide>
          <SwiperSlide>
            <InsightsSection />
          </SwiperSlide>
          <SwiperSlide>
            <RedditSection />
          </SwiperSlide>
          <SwiperSlide>
            <TradingSummarySection />
          </SwiperSlide>
          {/* <SwiperSlide>
            <ModelSection />
          </SwiperSlide> */}
        </Swiper>
      </div>

      {/* Testimonial Section */}

      <div
        className="carousel-container"
        style={{ backgroundColor: "white" }}
        id="testimonials"
      >
        <div className="landing-section-name">Testimonials</div>
        <span className="landing-section-title">
          Trusted by 2,000+ Users
        </span>
        <p className="landing-section-subtext">
          Get acces to action, <b>before</b> it happens! Access our <b>AI-driven Next-Day Screeners </b> 
           so you know the likely movers-and-shakers before everyone else. Created by Finance & AI experts 
          from  Wharton and Big Tech, respectively. 
        </p>

        {width <= 900 ? (
          <Swiper
            modules={[Autoplay, Pagination]}
            autoplay={{ delay: 7000 }}
            pagination={{ clickable: true }}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            style={{ borderRadius: 15, color: "#F8F8F5", marginTop: "3em" }}
          >
            <SwiperSlide style={{ height: "100%" }}>
              <div className="landing-testimonial-container">
                <p className="landing-testimonial-text">
                  "After no success with 'charting' apps, I tried MarketCrunch. Wow! A price prediction with insights I care for. 
                  Isn't that what we need? I won't chart anymore."
                </p>
                <div className="landing-testimonial-author">
                  <span className="landing-author-name">Julio S.</span>
                  <span className="landing-author-role">Novice Trader</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="landing-testimonial-container">
                <p className="landing-testimonial-text">
                  "This is my ritual now. Before Buy/Sell on RobinHood, I analyze a stock here to see news sentiment and esp. reddit comments. AI Prediction is super cool!"
                </p>
                <div className="landing-testimonial-author">
                  <span className="landing-author-name">S. Reddy</span>
                  <span className="landing-author-role">Director</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="landing-testimonial-container">
                <p className="landing-testimonial-text">
                  Transparent AI for Main St. Simple Insights! Especially, the Accuracy and Confidence 
                  level upfront - its like Amazon reviews, but for stocks"
                </p>
                <div className="landing-testimonial-author">
                  <span className="landing-author-name">K Coy.</span>
                  <span className="landing-author-role">Big Tech</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="landing-testimonial-container">
                <p className="landing-testimonial-text">
                  "Pretty unique take on Screeners. Predicts top gainers/losers for next day! Very Positive Experience (***5 Stars***)"
                </p>
                <div className="landing-testimonial-author">
                  <span className="landing-author-name">J. P.</span>
                  <span className="landing-author-role">Avid Trader</span>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="landing-testimonials-grid" id="testimonials">
            <div
              style={{
                padding: 20,
                backgroundColor: "#139C8D",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 15,
                margin: "0 1fr",
              }}
            >
              <p
                className="landing-testimonial-text"
                style={{ marginBottom: "3em" }}
              >
                "After many months of unsuccessful trading, I came across
                MarketCrunch and it really provided deep insights into near term
                price movements."
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: 18, color: "white", fontWeight: 600 }}>
                  Julio S.
                </span>
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: 400,
                    opacity: 0.75,
                  }}
                >
                  Avid Trader
                </span>
              </div>
            </div>

            <div
              style={{
                padding: 20,
                backgroundColor: "#139C8D",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 15,
                margin: "0 1fr",
              }}
            >
              <p
                className="landing-testimonial-text"
                style={{ marginBottom: "3em" }}
              >
                "For me, MarketCrunch revolutionized the way I approach trading.
                Now I come here first, before hitting the Trade button."
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: 18, color: "white", fontWeight: 600 }}>
                  Client
                </span>
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: 400,
                    opacity: 0.75,
                  }}
                >
                  Avid Trader
                </span>
              </div>
            </div>

            <div
              style={{
                padding: 20,
                backgroundColor: "#139C8D",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 15,
                margin: "0 1fr",
              }}
            >
              <p
                className="landing-testimonial-text"
                style={{ marginBottom: "3em" }}
              >
                "We live in the age of machines: hedge-funds and institutions
                dictate price movements. MarketCrunch helps the little guys by
                using machines to provide simple insights."
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: 18, color: "white", fontWeight: 600 }}>
                  K Coy.
                </span>
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: 400,
                    opacity: 0.75,
                  }}
                >
                  Avid Trader
                </span>
              </div>
            </div>

            <div
              style={{
                padding: 20,
                backgroundColor: "#139C8D",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 15,
                margin: "0 1fr",
              }}
            >
              <p
                className="landing-testimonial-text"
                style={{ marginBottom: "3em" }}
              >
                "A game-changer for small investors. MarketCrunch leveled the
                playing field."
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "start",
                }}
              >
                <span style={{ fontSize: 18, color: "white", fontWeight: 600 }}>
                  J.P.
                </span>
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: 400,
                    opacity: 0.75,
                  }}
                >
                  Avid Trader
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <TestimonialSection /> */}
      {/* <TeamSection /> */}

      <div className="carousel-container" id="team">
        <div className="landing-section-name">Team</div>
        <span className="landing-section-title">
          Founded on Democracy, Built by Experts
        </span>
        <p className="landing-section-subtext">
          We believe every investor deserves fast, reliable research—not
          fragmented sources. Whether you trade on fundamentals, technicals, or
          macro strategies, sound analysis should be at the heart of your
          decisions.
        </p>
        <p className="landing-section-subtext">
          We understand you don't always have the time or tools. So, we built a
          powerful AI-driven engine that delivers hedge-fund-grade insights in
          seconds. Built + backed by experts, our platform simplifies complex
          analysis for quick, actionable results.
        </p>
        <p className="landing-section-subtext" style={{ marginBottom: "2em" }}>
          No more wasting time staring at the screens. MarketCrunch AI gives you
          the research edge you need — in a simple, intuitive format. Get access
          to cutting-edge AI insights to help you make more informed decisions
          in under sixty seconds.
        </p>
        <a
          href={user ? "/dashboard" : "/login"}
          className="landing-cta-button"
          style={{
            backgroundColor: "#139C8D",
            display: "flex",
            alignSelf: "center",
            fontFamily: "Inter",
            fontSize: 15,
            padding: "10px 15px",
            border: "none",
            borderRadius: 7.5,
            color: "#F8F8F5",
            transition: "all 0.5s cubic-bezier(.075, .82, .165, 1)",
            fontWeight: 600,
            textDecoration: "none",
          }}
        >
          {user ? "Dashboard" : "Get Started, It's Free"}
        </a>
        <p
          className="landing-section-subtext"
          style={{
            display: "flex",
            alignSelf: "center",
            textTransform: "uppercase",
            marginTop: "5em",
            fontWeight: 500,
            marginBottom: "2em",
          }}
        >
          Backed by experts from
        </p>
        <Marquee
          style={{
            width: "100%",
            gap: 10,
            display: "flex",
            alignSelf: "center",
          }}
          speed={100}
        >
          <img
            src={Amazon}
            alt="Amazon Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "65%" }}
          />
          <img
            src={CMU}
            alt="Carnegie Mellon Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "80%" }}
          />
          <img
            src={CS}
            alt="Credit Suisse Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "75%" }}
          />
          <img
            src={Pinterest}
            alt="Pinterest Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "75%" }}
          />
          <img
            src={UCB}
            alt="UCB Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "75%" }}
          />
          <img
            src={UCLA}
            alt="UCLA Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "75%" }}
          />
          <img
            src={Wharton}
            alt="UPenn Wharton School of Business Logo"
            style={{ filter: "grayscale(100%)", opacity: 0.75, width: "75%" }}
          />
        </Marquee>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;

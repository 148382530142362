import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import './TechnicalIndicatorsPlot.css';

Chart.register(...registerables);

const availableFields = [
  'ema_10',
  'ema_20',
  'rsi_14',
  'bollinger_high',
  'bollinger_low',
  'macd',
  'macd_signal'
];

// Define your theme colors
const themeColors = {
  ema_10: '#1A2B4C', // Dark Blue
  ema_20: '#4B6185', // Lighter Blue
  rsi_14: '#DFFFD6', // Light Green
  bollinger_high: '#FF4C61', // Accent Pink
  bollinger_low: '#FFD6D6', // Light Red
  macd: '#FF9900', // Orange
  macd_signal: '#9933FF', // Purple
};

const MultiSelect = ({ options, selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="custom-select">
      <div 
        className="select-header" 
        onClick={() => setIsOpen(!isOpen)}
      >
        Pick Technicals ▼
      </div>
      {isOpen && (
        <div className="select-options">
          {options.map(({ key, label }) => (
            <label key={key} className="select-option">
              <input
                type="checkbox"
                checked={selected.includes(key)}
                onChange={(e) => {
                  const newSelected = e.target.checked
                    ? [...selected, key]
                    : selected.filter(item => item !== key);
                  onChange(['close', ...newSelected.filter(item => item !== 'close')]);
                }}
              />
              <span>{label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

const TechnicalIndicatorsPlot = ({ ticker }) => {
  const priceChartRef = useRef();
  const volumeChartRef = useRef();
  const priceChartInstance = useRef(null);
  const volumeChartInstance = useRef(null);
  const [selectedDatasets, setSelectedDatasets] = useState(['close', 'bollinger_high', 'bollinger_low']);
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
            ? "http://127.0.0.1:5000"
            : "https://flask-backend-52245432644.us-central1.run.app";
        const response = await axios.get(`${baseURL}/get-historical-features?ticker=${ticker}`);
        const data = response.data.date.map((date, index) => ({
          x: new Date(date),
          open: response.data.open[index],
          close: response.data.close[index],
          high: response.data.high[index],
          low: response.data.low[index],
          ema_10: response.data.ema_10[index],
          ema_20: response.data.ema_20[index],
          rsi_14: response.data.rsi_14[index],
          bollinger_high: response.data.bollinger_high[index],
          bollinger_low: response.data.bollinger_low[index],
          volume: response.data.volume[index],
          macd: response.data.macd[index],
          macd_signal: response.data.macd_signal[index],
        }));
        setTransformedData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [ticker]);

  useEffect(() => {
    if (!transformedData.length) return;

    const datasets = [
      {
        label: 'Open',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.open })),
        borderColor: 'blue',
        fill: false,
        hidden: !selectedDatasets.includes('open'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'Close',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.close })),
        borderColor: 'green',
        fill: false,
        hidden: !selectedDatasets.includes('close'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'High',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.high })),
        borderColor: 'red',
        fill: false,
        hidden: !selectedDatasets.includes('high'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'Low',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.low })),
        borderColor: 'orange',
        fill: false,
        hidden: !selectedDatasets.includes('low'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: '10-Day Exponential Moving Average (EMA)',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.ema_10 })),
        borderColor: '#1A2B4C',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('ema_10'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: '20-Day Exponential Moving Average (EMA)',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.ema_20 })),
        borderColor: '#4B6185',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('ema_20'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: '14-Day Relative Strength Index (RSI)',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.rsi_14 })),
        borderColor: '#DFFFD6',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('rsi_14'),
        yAxisID: 'y1',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'Bollinger Band (Upper)',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.bollinger_high })),
        borderColor: '#FF4C61',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('bollinger_high'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'Bollinger Band (Lower)',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.bollinger_low })),
        borderColor: '#FFD6D6',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('bollinger_low'),
        yAxisID: 'y',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: 'Moving Average Convergence Divergence',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.macd })),
        borderColor: '#FF9900',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('macd'),
        yAxisID: 'y1',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
      {
        label: '9-Day Signal Line of MACD',
        data: transformedData.map(d => ({ x: new Date(d.x.getTime() + d.x.getTimezoneOffset() * 60000), y: d.macd_signal })),
        borderColor: '#9933FF',
        fill: false,
        borderDash: [5, 5],
        hidden: !selectedDatasets.includes('macd_signal'),
        yAxisID: 'y1',
        borderWidth: 1,
        pointRadius: 2,
        pointHoverRadius: 4,
        tension: 0.4
      },
    ];

    if (priceChartInstance.current) {
      priceChartInstance.current.destroy();
    }

    const priceCtx = priceChartRef.current.getContext('2d');
    
    // Check if any secondary y-axis indicators are selected
    const secondaryAxisRequired = selectedDatasets.some(dataset => 
      ['rsi_14', 'macd', 'macd_signal'].includes(dataset)
    );

    priceChartInstance.current = new Chart(priceCtx, {
      type: 'line',
      data: { datasets },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            align: 'start',
            labels: {
              filter: function(legendItem, data) {
                return data.datasets[legendItem.datasetIndex].hidden === false;
              },
              font: {
                size: 10
              },
              boxWidth: 30,
              boxHeight: 1,
              padding: 10,
              usePointStyle: false
            }
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM dd',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
          y: {
            beginAtZero: false,
            position: 'left',
            title: {
              display: true,
              text: 'Price and Volatility',
              font: {
                size: 8,
                weight: 'bold'
              },
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          },
          y1: {
            display: secondaryAxisRequired,
            beginAtZero: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Trend and Momentum',
              font: {
                size: 8,
                weight: 'bold'
              },
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          },
        },
      },
    });

    if (volumeChartInstance.current) {
      volumeChartInstance.current.destroy();
    }

    const volumeCtx = volumeChartRef.current.getContext('2d');
    volumeChartInstance.current = new Chart(volumeCtx, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Volume',
            data: transformedData.map(d => ({ x: d.x, y: d.volume })),
            backgroundColor: 'rgba(0, 0, 255, 0.5)',
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM dd',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(value) {
                if (value >= 1000000) {
                  return (value / 1000000).toFixed(1) + 'M';
                } else if (value >= 1000) {
                  return (value / 1000).toFixed(1) + 'k';
                }
                return value;
              },
            },
          },
        },
      },
    });
  }, [transformedData, selectedDatasets]);

  return (
    <div className="technical-indicators-plot">
      <h3 style={{ fontSize: '14px' }}>Technicals Plot</h3>
      {transformedData.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
          Technicals data unavailable for plotting. We are working on adding more tickers to our database. Please check back soon!
        </div>
      ) : (
        <>
          <div className="chart-controls">
            <MultiSelect
              options={[
                { key: 'open', label: 'Open' },
                { key: 'high', label: 'High' },
                { key: 'low', label: 'Low' },
                { key: 'ema_10', label: '10-Day Exponential Moving Average (EMA)' },
                { key: 'ema_20', label: '20-Day Exponential Moving Average (EMA)' },
                { key: 'rsi_14', label: '14-Day Relative Strength Index (RSI)' },
                { key: 'bollinger_high', label: 'Bollinger Band (Upper)' },
                { key: 'bollinger_low', label: 'Bollinger Band (Lower)' },
                { key: 'macd', label: 'Moving Average Convergence Divergence' },
                { key: 'macd_signal', label: '9-Day Signal Line of MACD' },
              ]}
              selected={selectedDatasets}
              onChange={setSelectedDatasets}
            />
          </div>
          <canvas ref={priceChartRef}></canvas>
          <h3 style={{ fontSize: '14px' }}>Volume Plot</h3>
          <canvas ref={volumeChartRef}></canvas>
        </>
      )}
    </div>
  );
};

export default TechnicalIndicatorsPlot;
